import React from 'react'
import { Link } from 'gatsby'
import '../styles/components/_paginator.scss';

export default function Paginator({current_page_number, total_no_of_pages, next_page_path, previous_page_path}) {

    if(total_no_of_pages === 1){
        return (
            <div className='paginator flex-container'>
                <span className='paginator-count flex-container'>Page {current_page_number} of {total_no_of_pages}</span>
            </div>
        )
    }

    return (
        <div className='paginator flex-container'>

            {
            previous_page_path !== ''
            ?
            <Link className='paginator-prev flex-container' to={previous_page_path}><span>{`< Prev`}</span></Link>
            :
            <span className='paginator-prev flex-container'><span>{`< Prev`}</span></span>                
            }

            <span className='paginator-count flex-container'>Page {current_page_number} of {total_no_of_pages}</span>

            {
            next_page_path !== ''
            ?
            <Link className='paginator-next flex-container' to={next_page_path}><span>{`Next >`}</span></Link>
            :
            <span className='paginator-next flex-container'><span>{`Next >`}</span></span>                
            }

        </div>
    )
}
