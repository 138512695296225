import React from 'react'
import Layout from '../../layout';
import { Link, graphql } from 'gatsby';
import BlogCard from "../../BlogCard";
import Paginator from '../../Paginator';
import removeURLInvalidChars from '../../../helpers/removeURLInvalidChars';
import "../../../styles/components/blog/_top-categories.scss";

export default function BlogListing({pageContext, data}) {
    
    let {edges: posts} = data.allMarkdownRemark;

    let { humanPageNumber, numberOfPages, nextPagePath, previousPagePath, top_categories } = pageContext;

    return (
        <Layout width_restrict_all={true} meta_title={`All Blog Posts${humanPageNumber !== 1 ? ` (Pg. ${humanPageNumber})` : ''}`} meta_desc={`Check out all of Jonny's blog posts!`} meta_img={`/assets/metadata_images/default/blog.png`}>
            <h1>All Blog Posts</h1>

            {humanPageNumber === 1 && top_categories.length > 3 ? <TopCategories top_categories_arr={top_categories} /> : null}

            <div className={`listing-wrapper flex-container-column`}>

                {posts.map((post, i) => {

                    let frontmatter = post.node.frontmatter;
                    let slug = post.node.fields.slug;
                    let img_fluid = post.node.frontmatter.image.childImageSharp.fluid

                    return <BlogCard frontmatter={frontmatter} slug={slug} img_fluid={img_fluid} key={i} />

                })}

            </div>

            <Paginator 
            current_page_number={humanPageNumber} 
            total_no_of_pages={numberOfPages}
            next_page_path={nextPagePath}
            previous_page_path={previousPagePath}
            />

        </Layout>
    )
}


function TopCategories({top_categories_arr}) {

  return (
    <div className="top-categories">
      <h4>Top Categories</h4>
      <div class="top-categories__container flex-container">
        {top_categories_arr.map(category_info => {
          return <Link className='top-categories__container__category no-link-styles' to={`/blog/category/${removeURLInvalidChars(category_info[0])}`}>
                    {category_info[0]} <span className='top-categories__container__category__counter flex-container'>{category_info[1]}</span>
                  </Link>
        })}
      </div>
    </div>
  )

}


export const query = graphql`
query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark (
        filter: { collection: { eq: "blogpost" } }
        skip: $skip
        limit: $limit
        sort: { fields: [frontmatter___date], order: DESC }
        ) {
      edges {
        node {
          id
          frontmatter {
            title
            category
            tags
            description
            date(formatString: "MMM D, YYYY")
            image {
              childImageSharp {
                fluid {
                  base64
                  aspectRatio
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

