import React from 'react';
import "../styles/components/blog/_blog-card.scss";
import Img from "gatsby-image";
import { Link } from 'gatsby';

import '../styles/components/_blog_card.scss';
import removeURLInvalidChars from '../helpers/removeURLInvalidChars';

export default function BlogCard({frontmatter, slug, img_fluid}) {

    let {title, date, description, tags, category} = frontmatter;

    return (

        <div className='blog-card flex-container width-100'>
          <div className="blog-card__inner flex-container width-100 no-link-styles">

              <div className='blog-card__inner__content width-100'>

                  <Link to={`/blog/post${slug}`}><span className="blog-card__inner__content__title">{title}</span></Link>
                  <div className='blog-card__inner__content__info'>
                    <span className='blog-card__inner__content__info__date'>{date}</span>
                    <Link to={`/blog/category/${removeURLInvalidChars(category[0])}`} className='no-link-styles blog-card__inner__content__info__category'>{category[0]}</Link>
                  </div>
                  <p className="blog-card__inner__content__description">{description}</p>

                  <TagList title={"tag"} arr={tags}/>

              </div>
              <div className="blog-card__inner__img">
                  <i className="blog-planets-detail"></i>
                  <Link to={`/blog/post${slug}`}><Img fluid={{...img_fluid, aspectRatio: 1}} /> </Link>
              </div>
          </div>
        </div>

    )

}


function TagList({arr}) {

    if(arr.length === 0 || !arr) return(null);

    return (
        <div className='blog-card__inner__content__taglist width-100'>
            <div className='blog-card__inner__content__taglist__inner flex-container'>
                    {arr.map((item, i) => {
                        return <Link to={`/blog/tag/${removeURLInvalidChars(item)}`} key={i} className='blog-card__inner__content__taglist__inner__tag no-link-styles'>{item}</Link>
                    })}
            </div>
        </div>
    )


}